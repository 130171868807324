let parentData = {
		"school_type": "",
		"school_type_id": "",
		"grade": "",
		"fees": [],
		"board": [],
		"region": [],
		"age": ""
};

let stepJson = {
		"boarding": {
				"grade": {
						"Class Pre Nursery": ["16", "16"],
						"Class Nursery": ["1", "1"],
						"Class 1": ["3", "3"],
						"Class 2": ["4", "4"],
						"Class 3": ["5", "5"],
						"Class 4": ["6", "6"],
						"Class 5": ["7", "7"],
						"Class 6": ["8", "8"],
						"Class 7": ["9", "9"],
						"Class 8": ["10", "10"],
						"Class 9": ["11", "11"],
						"Class 10": ["12", "12"],
						"Class 11": ["13", "13"],
						"Class 12": ["14", "14"]

				},
				"fees": {
						"< 3 Lac": ["fee->500_<300000", "500-300000"],
						"3 Lac to 5 Lac": ["fee->300000_<500000", "300000-500000"],
						"5 Lac to 7 Lac": ["fee->500000_<700000", "500000-700000"],
						"7 Lac to 10 Lac": ["fee->700000_<1000000", "700000-1000000"],
						"> 10 Lac": ["fee->1000000", "1000000-2000000"]
				},
				"board": {
						"CBSE": ["board-1", "1"],
						"ICSE/ISC": ["board-2", "2"],
						"IB": ["board-3", "3"],
						"IGCSE": ["board-6", "6"],
						"State Board": ["board-5", "5"],
						"Other": ["board-4", "4"]
				},
				"region": {
						"North": ["region-1", "north"],
						"East": ["region-2", "east"],
						"West": ["region-4", "west"],
						"South": ["region-3", "south"],
						"Central": ["region-5", "central"]
				}
		},
		"day": {
				"grade": {
						"Pre Nursery": ["16", "16"],
						"Nursery": ["1", "1"],
						"Class 1": ["3", "3"],
						"Class 2": ["4", "4"],
						"Class 3": ["5", "5"],
						"Class 4": ["6", "6"],
						"Class 5": ["7", "7"],
						"Class 6": ["8", "8"],
						"Class 7": ["9", "9"],
						"Class 8": ["10", "10"],
						"Class 9": ["11", "11"],
						"Class 10": ["12", "12"],
						"Class 11": ["13", "13"],
						"Class 12": ["14", "14"]

				},
				"fees": {
						"< 30K": ["fee-<30000", "500-30000"],
						"30K to 50K": ["fee->30000_<50000", "30000-50000"],
						"50K to 70K": ["fee->50000_<70000", "50000-70000"],
						"70k to 1Lac": ["fee->70000_<100000", "70000-100000"],
						"1 Lac to 2 Lac": ["fee->100000_<200000", "100000-200000"],
						"> 2 Lac": ["fee->200000", "200000-1000000"]
				},
				"board": {
						"CBSE": ["board-1", "1"],
						"ICSE/ISC": ["board-2", "2"],
						"IB": ["board-3", "3"],
						"IGCSE": ["board-6", "6"],
						"State Board": ["board-5", "5"],
						"Other": ["board-4", "4"]
				}
		},
		"pre": {
				"age": {
						"< 1Year": ["<1year", "12"],
						"1Year to 2Year": ["1to2", "18"],
						"2Year to 3Year": ["2to3", "30"],
						"> 3Year": [">3year", "36"]

				},
				"fees": {
						"< 2.5K": ["fee-<2500", "500-2500"],
						"2.5K to 5K": ["fee->2500_<5000", "2500-5000"],
						"5K to 8K": ["fee->5000_<8000", "5000-8000"],
						"> 8K": ["fee->8000", "8000-100000"]
				}
		},
		"pu": {
				"fees": {
						"< 30K": ["fee-<30000", "500-30000"],
						"30K to 50K": ["fee->30000_<50000", "30000-50000"],
						"50K to 70K": ["fee->50000_<70000", "50000-70000"],
						"70k to 1Lac": ["fee->70000_<100000", "70000-100000"],
						"1 Lac to 2 Lac": ["fee->100000_<200000", "100000-200000"],
						"> 2 Lac": ["fee->200000", "200000-1000000"]
				},
				"board": {
						"CBSE": ["board-1", "1"],
						"ICSE/ISC": ["board-2", "2"],
						"IB": ["board-3", "3"],
						"IGCSE": ["board-6", "6"],
						"State Board": ["board-5", "5"],
						"Other": ["board-4", "4"]
				}
		}
};

/*console.log(stepJson, 'stepJson');*/

$(document).ready(function () {

		let stepsCount = $('.step-assistant').length - 1;
		let stepNum = 0;


		// $('#assistantModal').modal('show');

		$("#progress-count").html(`0 of ${stepsCount}`);

		/* ---------------------- assistant prev button ---------------------- */
		$("#prev-nav").on('click', function () {
				if (user) $('#get_in_touch').hide();
				if (stepNum > 0) {
						stepNum -= 1;
						let assistantStep = $(`#assistant-step-${stepNum}`);
						$(".step-assistant").removeClass('active');
						assistantStep.addClass('active');
						let percent = (stepNum / stepsCount) * 100;
						$("#assistant-progress").css("width", percent + "%");
						if (!assistantStep.hasClass('login-step')) {
								$('#submitAssitant').hide();
								$('#next-nav').show();
						}

						$("#progress-count").html(stepNum + " of " + stepsCount);
				}
				if ($('#assistant-step-0').hasClass('active')) {
						$('#prev-nav').addClass('d-none');
						console.log('asdasdasdas');
				}
		});
		/* ---------------------- /assistant prev button ---------------------- */

		/* ---------------------- assistant next button ---------------------- */
		$("#next-nav").on('click', function () {

				if (user) $('#get_in_touch').hide();

				$('#prev-nav').removeClass('d-none');
				if ($('#assistant-step-0').hasClass('active')) {
						if (!(parentData.school_type)) {
								console.log('Select A School');
								return;
						}
				}
				if ($('.step-assistant.active').hasClass('dynamic-step')) {
						let checkData = $(".step-assistant.active").attr("data-step-type");

						if (parentData[checkData].length === 0) {
								$('.step-assistant.active .disclaimer').show();
								return;
						}
				}
				if ($('.final-step').hasClass('active') && $("#submitAssitant:contains('options')")) {
						$('#prev-nav').hide();
						$('#submitAssitant').css('width', '100%');
				}

				let gradeHeader = '';
				let feesHeader = '';
				let boardHeader = '';
				let regionHeader = '';
				let ageHeader = '';

				if (parentData['grade'].length !== 0) {
						gradeHeader = ' for grade <span class="selected-tag">' + parentData['grade'] + '</span>';
				}
				if (parentData['fees'].length !== 0) {
						feesHeader = ' in the fee range <span class="selected-tag">' + parentData['fees'].join() + '</span>';
				}
				if (parentData['board'].length !== 0) {
						boardHeader = ' with <span class="selected-tag">' + parentData['board'].join() + '</span>';
				}
				if (parentData['region'].length !== 0) {
						regionHeader = ' in <span class="selected-tag">' + parentData['region'].join() + ' region</span>';
				}
				if (parentData['age'].length !== 0) {
						ageHeader = ' for age group <span class="selected-tag">' + parentData['age'] + '</span>';
				}

				let extraHeader = gradeHeader + feesHeader + boardHeader + regionHeader + ageHeader;

				$('.extra-heading').html(extraHeader);

				if (stepNum < stepsCount) {
						stepNum += 1;
						$(".step-assistant").removeClass('active');
						$("#assistant-step-" + stepNum).addClass('active');
						if ($('#assistant-step-' + stepNum).hasClass('login-step')) {
								$('#submitAssitant').show();
								$('#next-nav').hide();
						}
						let percent = (stepNum / stepsCount) * 100;
						$("#assistant-progress").css("width", percent + "%");
						$("#progress-count").html(stepNum + " of " + stepsCount);
				}

				Object.entries(parentData).forEach(([key, val]) => {
						if (val.length === 0) {
								$('#selector-' + key).hide();
						} else if (val.length !== 0 && Array.isArray(val)) {
								let displayData = '';
								val.forEach(element => {
										displayData += '<span>' + element + '</span>';
								});
								$('#selector-' + key + ' .selector-info').html(displayData);
								$('#selector-' + key).show();
						} else {
								$('#selector-' + key + ' .selector-info').html("<span class='text-capitalize' >" + val + "</span>");
								$('#selector-' + key).show();
						}
				});

		});
		/* ---------------------- assistant next button ---------------------- */

		/* ---------------------- select school type ---------------------- */
		$(".school-selector").on('click', function (e) {
				let assistantSchoolType = $(this).attr("data-schooltype");
				let assistantSchoolTypeId = parseInt($(this).attr("data-schooltypeid"));
				let stepAddCount = 0;

				$(".school-selector").removeClass('selected');
				$(this).addClass('selected');

				if (assistantSchoolTypeId === 3) {
						$('.preferred-location-hide').removeClass('d-flex').addClass('d-none');
						$('.assistant-gender').removeClass('d-none').addClass('d-flex');
						$('.preferred-location-form').addClass('preferred-location-day');
				} else {
						$('.preferred-location-hide').removeClass('d-none').addClass('d-flex');
						$('.assistant-gender').addClass('d-none').removeClass('d-flex');
						$('.preferred-location-form').removeClass('preferred-location-day');
				}

				parentData = {
						"school_type": assistantSchoolType,
						"grade": "",
						"fees": [],
						"board": [],
						"region": [],
						"age": ""
				};

				$("div").remove(".dynamic-step");

				Object.entries(stepJson[assistantSchoolType]).forEach(([key, schoolOptions]) => {

						let headingSub = '';
						let assistantSelector = '';
						if (key === 'grade') {
								headingSub = 'Which class do you want admission in?';

						} else if (key === 'fees') {
								headingSub = 'What is the ' + ((assistantSchoolType === 'pre') ? 'monthly' : 'annual') + ' fee range you are looking for?';
						} else if (key === 'board') {
								headingSub = 'What is your preference for the Board?';
						} else if (key === 'region') {
								headingSub = 'What is your preferred location?';
						} else if (key === 'age') {
								headingSub = 'What is preferred age bracket?';
						}

						for (let option in schoolOptions) {
								assistantSelector += `<span type="button" 
																							class="assistant-tag"
																							data-tag-wrapper="${key}" 
																							data-assistant-tag="${option}" 
																							data-filter="${schoolOptions[option][1]}" 
																							data-id="${schoolOptions[option][0]}">${(key === 'grade') ? option.replace('Class ', '') : option}</span>`;
						}


						$("#assistant-step-" + stepAddCount).after(
								`<div id="assistant-step-${stepAddCount + 1}" class="step-assistant dynamic-step" data-step-type="${key}">
									<div class="bottom d-flex flex-column align-items-center">
										<div class="questionnaire text-center">
											<span class="h2">You are looking for admission in a 
												<span class="text-capitalize selected-tag">${((assistantSchoolType === 'pu') ? "PU College" : assistantSchoolType + " School")}</span>
												<span class="extra-heading"></span>
											${headingSub}
											</span>
											<p>${((key === "grade" || key === "age") ? "Select any one" : "You can select multiple")}</p>
										</div>
										<div class="cards d-flex">
											<div class="assistant-selector assistant-${key}-wrapper">${assistantSelector}</div>
										</div>
										<div class="disclaimer" style="display: none">
											<p>Disclaimer : Please Select at least one.</p>
										</div>
									</div>
								</div>`
						);


						$(".final-step").attr("id", "assistant-step-" + (stepAddCount + 2));
						$(".login-step").attr("id", "assistant-step-" + (stepAddCount + 3));

						stepsCount = $('.step-assistant').length - 1;
						$("#progress-count").html("0 of " + stepsCount);
						stepAddCount++;
				});
		});
		/* ---------------------- /select school type ---------------------- */

		/* ---------------------- dynamic steps by selected school type ---------------------- */
		$("body").on('click', '.assistant-tag', function () {
				let assistantTag = $(this);
				$('.step-assistant.active .disclaimer').hide();
				let stepType = assistantTag.attr('data-tag-wrapper');

				if (stepType === "grade" || stepType === "age") {
						$('.dynamic-step.active .assistant-tag').removeClass('selected');
						$(this).addClass('selected');
						if (!!stepType) parentData[stepType] = $(this).attr("data-assistant-tag");

				} else {
						$(this).toggleClass('selected');
						let selectData = $(this).attr("data-assistant-tag");
						if ($(this).hasClass("selected")) {
								if (!!stepType) parentData[stepType].push(selectData);

						} else {
								let indexSelectData = parentData[stepType].indexOf(selectData);
								if (!!stepType) parentData[stepType].splice(indexSelectData, 1);
						}
				}

		});
		/* ---------------------- dynamic steps by selected school type ---------------------- */

		/* ---------------------- mobile phone input ---------------------- */
		
		$('#AssistantModal').on('show.bs.modal', () => {
				let assistLoginPhone = $('#assist_login_phone');

				if(assistLoginPhone.parent('.iti').length === 0){
						$('#assist_login_phone').intlTelInput({
								separateDialCode: true,
								preferredCountries: ["in"],
								excludeCountries: ["am", "az", "ge", "de", "id", "kz", "kg", "ua", "uz", "tj"],
								autoPlaceholder: "Enter Mobile Number"
						});
				}
				
		});
		
		/* ---------------------- /mobile phone input ---------------------- */

		/* ---------------------- submit assistant form ---------------------- */
		$('#submitAssitant').on('click', function (e) {
				if (user) {
						AssistFinish();
				} else {
						$('#assist-login-form').trigger('submit');
				}
		});
		/* ---------------------- /submit assistant form ---------------------- */

		/* ---------------------- otp input events ---------------------- */
		let assistOtpInputs = '#otp-container input[type=number]';
		$('body').on('input', assistOtpInputs, function (event) {
				inputNumberLength(event)
		});

		$('body').on('keyup', assistOtpInputs, function (event) {
				let currentInput = $(this);
				let allOtpInput = $('#assist-login-form .otp-input-wrapper input[type=number]');
				let val = currentInput.attr('data-tabsequence');

				$('.assistant-otp-error').text('');

				if (allOtpInput[val - 1].value !== '' && val < 4) {
						allOtpInput[val].focus()
				} else if (allOtpInput[val - 1].value === '' && val > 1) {
						allOtpInput[val - 2].focus()
				}

				let otp1 = allOtpInput.eq(0).val();
				let otp2 = allOtpInput.eq(1).val();
				let otp3 = allOtpInput.eq(2).val();
				let otp4 = allOtpInput.eq(3).val();

				let otpStr = '';
				if (!!otp1) otpStr += otp1;
				if (!!otp2) otpStr += otp2;
				if (!!otp3) otpStr += otp3;
				if (!!otp4) otpStr += otp4;

				$('#assist_login_otp').val(otpStr);

		});
		/* ---------------------- /otp input events ---------------------- */


});
